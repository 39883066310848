<template>
  <video
    id="video"
    ref="video"
    :width="width"
    :height="height"
    :src="src"
    :autoplay="autoplay"
    :srcObject.prop="stream"
  ></video>
</template>

<script>
export default {
  name: "AppWebcam",
  props: {
    autoplay: {
      type: Boolean,
      default: true,
    },
    width: {
      type: Number,
    },
    height: {
      type: Number,
    },
    mirror: {
      type: Boolean,
      default: true,
    },
    screenshotFormat: {
      type: String,
      default: "image/jpeg",
    },
  },
  data() {
    return {
      video: "",
      src: "",
      stream: "",
      hasUserMedia: false,
      styleObject: {
        transform: "scale(-1, 1)",
        filter: "FlipH",
      },
    };
  },

  methods: {
    getPhoto() {
      if (!this.hasUserMedia) return null;

      const canvas = this.getCanvas();
      return canvas.toDataURL(this.screenshotFormat);
    },
    getCanvas() {
      if (!this.hasUserMedia) return null;

      const video = this.$refs.video;
      if (!this.ctx) {
        const canvas = document.createElement("canvas");
        canvas.height = video.clientHeight;
        canvas.width = video.clientWidth;
        this.canvas = canvas;

        this.ctx = canvas.getContext("2d");

        /*if (this.mirror) {
        const context = canvas.getContext('2d');
        context.translate(canvas.width, 0);
        context.scale(-1, 1);
        this.ctx = context;
        } else {
        this.ctx = canvas.getContext('2d');
        }*/
      }

      const { ctx, canvas } = this;
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      return canvas;
    },
  },

  mounted: function () {
    this.video = this.$refs.video;
    // const avideo = document.getElementById("video");
    navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia ||
      navigator.oGetUserMedia;

    // https://forum.vuejs.org/t/binding-to-srcobject-attribute/39323/2
    if (navigator.getUserMedia) {
      navigator.getUserMedia(
        { video: true, audio: false },
        (stream) => {
          console.log(stream);
          this.src = stream;
          this.stream = stream;
          this.hasUserMedia = true;

          // avideo.srcObject = stream;
          // avideo.play();
        },
        (error) => {
          console.log(error);
        }
      );
    }
  },

  beforeDestroy: function () {
    this.video.pause();
    this.src = "";
    this.stream.getTracks()[0].stop();
  },

  destroyed: function () {
    console.log("Destroyed");
  },
};
</script>

<style scoped></style>
