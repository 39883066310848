<template>
  <div
    class="d-flex flex-column my-5 bg-light rounded px-10 py-5 border-bottom"
  >
    <!-- begin:: Camera  -->
    <div
      class="d-flex my-5 flex-column flex-md-row"
      v-if="getPhase === 'WEBCAM_PHASE'"
    >
      <AppWebcam ref="webcam"></AppWebcam>
      <div class="mx-auto justify-content-center d-flex flex-column">
        <b-button class="mb-5 mt-5 mt-md-0" variant="primary" @click="takePhoto"
          >Take Photo</b-button
        >
        <b-button
          class=""
          variant="light"
          @click="phase = phase === 'WEBCAM_PHASE' ? 'default' : 'WEBCAM_PHASE'"
          >Cancel</b-button
        >
      </div>
    </div>
    <!-- end:: Camera  -->

    <!-- begin:: File upload input  -->
    <div class="d-flex flex-column flex-lg-row my-10 pt-2 pb-5">
      <file-pond
        class="mr-lg-10 my-5 my-lg-0 flex-grow-1"
        name="test"
        ref="pond"
        label-idle="Drop files here..."
        v-bind:allow-multiple="false"
        accepted-file-types="image/jpeg, image/png"
        :files="myFiles"
        v-on:init="handleFilePondInit"
      >
      </file-pond>
      <b-button
        v-if="getPhase === 'default'"
        class="align-self-center mt-2 mt-lg-0 flex-grow-1 flex-lg-grow-0"
        variant="light-primary"
        @click="phase = phase === 'WEBCAM_PHASE' ? 'default' : 'WEBCAM_PHASE'"
      >
        Webcam
      </b-button>
      <b-button
        class="align-self-center mt-2 mt-lg-0 flex-grow-1 flex-lg-grow-0"
        variant="light-primary"
        v-on:click="uploadPicture"
        v-bind:class="{ 'spinner spinner-right spinner-light': imageBusy }"
      >
        {{ $t("BUTTON.UPLOAD") }}
      </b-button>
    </div>
    <!-- end:: File upload input  -->
  </div>
</template>

<script>
// Import Vue FilePond
import vueFilePond from "vue-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import FilePond plugins
// Please note that you need to install these plugins separately
// Import image preview plugin styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import AppWebcam from "@/modules/core/components/camera/AppWebcam";

// Import image preview and file type validation plugins
const FilePond = vueFilePond(FilePondPluginImagePreview);

export default {
  name: "CommonSchoolImageUpload",
  components: {
    AppWebcam,
    FilePond,
  },

  props: {
    imageBusy: {
      type: Boolean,
      default: false,
    },
    imageUpload: null,
    phase: {
      type: String,
      default: "default",
    },
  },

  data() {
    return {
      image: null,
      image_url: "",
      myFiles: [],
    };
  },

  methods: {
    uploadPicture() {
      console.log("File", this.$refs.pond.getFiles()[0]);
      this.$refs.pond.getFiles()[0].file;
      // this.imageBusy = !this.imageBusy;

      this.$emit("uploadClicked", this.$refs.pond.getFiles()[0].file);
    },
    handleFilePondInit: function () {
      console.log("FilePond has initialized");

      // FilePond instance methods are available on `this.$refs.pond`
    },
    takePhoto() {
      const photo = this.$refs.webcam.getPhoto();
      this.$refs.pond.addFile(photo);
    },
  },

  computed: {
    getPhase() {
      console.log("Compute phase", this.phase, this.$refs.pond);
      return this.phase;
    },
  },

  watch: {
    phase: {
      handler(newPhase) {
        console.log("New Phase is", newPhase);
        if (newPhase === "upload" && this.$refs.pond) {
          this.$refs.pond.removeFile();
          this.phase = "default";
        }
      },
    },
  },
};
</script>

<style scoped></style>
