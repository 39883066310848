<template>
  <div class="d-flex align-items-center">
    <!--begin::Symbol-->
    <div class="symbol symbol-40 symbol-light mr-5">
      <span class="symbol-label">
        <inline-svg :src="iconSvg"></inline-svg>
      </span>
    </div>
    <!--end::Symbol-->
    <!--begin::Text-->
    <div class="d-flex flex-column font-weight-bold">
      <a href="#" class="text-dark text-hover-primary mb-1 font-size-lg">{{
        title
      }}</a>
      <span>{{ body }}</span>
    </div>
    <!--end::Text-->
  </div>
</template>

<script>
export default {
  name: "PersonalProfileListItem",
  props: {
    title: String,
    body: String,
    iconSvg: String,
  },
};
</script>

<style scoped></style>
