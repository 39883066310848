<template>
  <div>
    <div v-if="!isBusy">
      <b-card bg-variant="">
        <div class="d-flex flex-column flex-md-row">
          <b-avatar
            class="my-auto ml-5"
            size="13em"
            :src="employee.image_url"
            variant="light"
          ></b-avatar>
          <div class="px-">
            <button
              class="btn btn-icon btn-light btn-hover-primary btn-lg mr-3"
              v-on:click="openModal"
              v-b-tooltip.hover
              :title="$t('Employee_Personal.Tooltip.Profile')"
            >
              <span class="svg-icon svg-icon-lg svg-icon-primary menu-icon">
                <inline-svg src="/media/svg/icons/Communication/Write.svg" />
              </span>
            </button>
          </div>
          <div
            class="d-flex flex-column px-md-5 ml-md-10 mt-5 mt-md-0 flex-grow-1"
          >
            <h3 class="font-weight-bolder font-size-h2 mb-1">
              <a href="#" class="text-dark-75">{{
                `${employee.first_name}  ${employee.last_name}`
              }}</a>
            </h3>
            <div class="d-flex mb-3 mt-5">
              <span class="text-dark-50 flex-root font-weight-bold"
                >Unique Code</span
              >
              <span class="text-dark flex-root font-weight-bold">{{
                employee.unique_code
              }}</span>
            </div>
            <div class="d-flex mb-3">
              <span class="text-dark-50 flex-root font-weight-bold">Email</span>
              <span class="text-dark flex-root font-weight-bold">{{
                employee.email
              }}</span>
            </div>
            <div class="d-flex mb-3">
              <span class="text-dark-50 flex-root font-weight-bold"
                >Employment Date</span
              >
              <span class="text-dark flex-root font-weight-bold">{{
                employee.employment_date
              }}</span>
            </div>
          </div>
        </div>
      </b-card>
      <div class="d-flex flex-column flex-md-row">
        <b-card
          v-if="employee.personal_detail"
          class="mt-5 flex-grow-1 mr-md-5"
        >
          <!-- start: Personal Details          -->
          <h3 class="mb-5">{{ $t("PERSONAL.PERSONALDETAILS") }}</h3>
          <PersonalProfileListItem
            class="mb-5"
            title="Contact Number"
            :body="employee.personal_detail.phone_number"
            icon-svg="/media/svg/icons/Home/Alarm-clock.svg"
          ></PersonalProfileListItem>

          <PersonalProfileListItem
            class="mb-5"
            title="Gender"
            :body="employee.personal_detail.gender"
            icon-svg="/media/svg/icons/Home/Alarm-clock.svg"
          ></PersonalProfileListItem>

          <PersonalProfileListItem
            class="mb-5"
            title="Marital Status"
            :body="employee.personal_detail.marital_status"
            icon-svg="/media/svg/icons/Home/Alarm-clock.svg"
          ></PersonalProfileListItem>

          <PersonalProfileListItem
            class="mb-5"
            :title="employee.personal_detail.identity_type"
            :body="employee.personal_detail.identity_number"
            icon-svg="/media/svg/icons/Home/Alarm-clock.svg"
          ></PersonalProfileListItem>

          <PersonalProfileListItem
            class="mb-5"
            title="Address"
            :body="employee.personal_detail.address"
            icon-svg="/media/svg/icons/Home/Alarm-clock.svg"
          ></PersonalProfileListItem>
          <!-- end: Personal Details          -->
        </b-card>
        <b-card v-if="employee.contact_detail" class="mt-5 flex-grow-1">
          <h3 class="mb-5">{{ $t("PERSONAL.EMERGENCYCONT") }}</h3>
          <PersonalProfileListItem
            class="mb-5"
            title="Name"
            :body="employee.contact_detail.emergency_name"
            icon-svg="/media/svg/icons/Home/Alarm-clock.svg"
          ></PersonalProfileListItem>

          <PersonalProfileListItem
            class="mb-5"
            title="Relationship"
            :body="employee.contact_detail.relation"
            icon-svg="/media/svg/icons/Home/Alarm-clock.svg"
          ></PersonalProfileListItem>

          <PersonalProfileListItem
            class="mb-5"
            title="Contact"
            :body="employee.contact_detail.contact_number"
            icon-svg="/media/svg/icons/Home/Alarm-clock.svg"
          ></PersonalProfileListItem>

          <PersonalProfileListItem
            class="mb-5"
            title="Address"
            :body="employee.contact_detail.address"
            icon-svg="/media/svg/icons/Home/Alarm-clock.svg"
          ></PersonalProfileListItem>
        </b-card>
      </div>
    </div>
    <b-skeleton-wrapper v-if="isBusy">
      <b-card>
        <div class="mb-5">
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </div>

        <div class="mb-5">
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </div>
      </b-card>
    </b-skeleton-wrapper>

    <PersonalProfileModal :employee="employee"></PersonalProfileModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { alert } from "sweetalert2";
import PersonalProfileListItem from "@/modules/personal/components/shared/PersonalProfileListItem";
import PersonalProfileModal from "@/modules/personal/components/profile/PersonalProfileModal";

export default {
  name: "PersonalProfilePage",
  components: { PersonalProfileModal, PersonalProfileListItem },
  data() {
    return {
      isBusy: false,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.isBusy = true;
      return this.$store
        .dispatch("getEmployeePersonalProfile")
        .then((response) => {
          console.log("Received", response);

          return response.data.data;
        })
        .catch(() => {
          alert({
            title: "Failed",
          });
        })
        .finally(() => (this.isBusy = false));
    },

    openModal() {
      this.$bvModal.show("personal-profile-modal");
    },
  },
  computed: {
    ...mapGetters({ employee: "getEmployeePersonalProfile" }),
  },
};
</script>

<style scoped></style>
