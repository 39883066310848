<template>
  <b-modal
    id="personal-profile-modal"
    :title="$t('Employee_Personal.Profile.Modal.Title')"
    ok-only
    :ok-title="$t('COMMON.Actions.Close')"
    size="xl"
  >
    <div class="d-flex justify-content-center">
      <b-avatar
        :src="employee.image_url"
        variant="light"
        size="15em"
        class="mx-auto mb-5"
      ></b-avatar>
    </div>

    <CommonSchoolImageUpload
      @uploadClicked="handleUpload"
      :phase="phase"
      @cancel-clicked="changePhase"
      @webcam-clicked="changePhase"
      :image-busy="imageBusy"
    >
    </CommonSchoolImageUpload>
  </b-modal>
</template>

<script>
import CommonSchoolImageUpload from "@/modules/company/components/shared/utility/CommonSchoolImageUpload";

export default {
  name: "PersonalProfileModal",
  components: { CommonSchoolImageUpload },
  props: {
    employee: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      holderTitle: "Personal Employee Photo",
      isBusy: false,
      phase: "default",
      imageBusy: false,
    };
  },
  methods: {
    /**
     *
     * @param image
     */
    handleUpload(image) {
      console.log("Personal image: ", image);

      this.imageBusy = true;
      this.$store
        .dispatch("uploadPersonalImage", {
          image: image,
        })
        .then((res) => {
          console.log(res.data);
          this.$bvModal.msgBoxOk(this.$t("COMMON.Upload.Image.Success"), {
            title: this.$t("COMMON.Upload.Image.Success_Title"),
            centered: true,
          });
          this.employee.image_url = res.data.url;
        })
        .finally(() => {
          this.phase = "default";
          this.$emit("detailsUpdated");
          this.imageBusy = false;
        });
    },

    /**
     * Change upload phase
     */
    changePhase() {
      this.phase = this.phase === "WEBCAM_PHASE" ? "default" : "WEBCAM_PHASE";
    },
  },
};
</script>

<style scoped></style>
